import { NavLink } from "@remix-run/react";
import useLinkHref from "app/utils/href";
import classNames from "classnames";
import PropTypes from "prop-types";

const SubLink = ({ hash, sublink, NavLinkProps }) => {
  const href = useLinkHref(`${sublink.url}${hash ?? ""}`);

  return (
    <div className="text-sm font-medium text-white bg-gradient-to-b from-yellowgreen to-cyan-dark xl:text-xs xl:font-semibold">
      <NavLink
        {...NavLinkProps}
        to={sublink.external ? sublink.url : href}
        id={sublink.id}
        className={({ isActive }) =>
          classNames(
            "flex items-center gap-x-2 py-3.5 hover:w-[99%] hover:bg-secondary-dark ltr:pl-12 rtl:pr-12 xl:py-3 hover:xl:w-auto hover:xl:bg-primary-light ltr:xl:pl-3 rtl:xl:pr-3",
            isActive
              ? "w-[99%] bg-secondary-dark xl:w-auto xl:border-x-green xl:bg-primary-light xl:font-bold ltr:xl:border-r-[4px] rtl:xl:border-l-[4px]"
              : "bg-primary-pale xl:border-x-primary-pale"
          )
        }
        reloadDocument={sublink.reloadDocument}
        target={sublink.external ? "_blank" : undefined}
        rel={sublink.external ? "noreferrer" : undefined}
      >
        {sublink.label}
        {sublink.icon && (
          <div>
            <img
              alt=""
              src={sublink.icon}
              className="w-3.5 brightness-0 invert"
            />
          </div>
        )}
      </NavLink>
    </div>
  );
};

const SubLinks = ({ isDropdownOpen, sublinks, hash, NavLinkProps }) => {
  return (
    <div
      className={classNames(
        "flex flex-col transition-all overflow-hidden duration-100 ease-in-out xl:visible xl:absolute xl:top-10 xl:block xl:h-auto xl:w-44 xl:rounded-b-lg xl:border-2 xl:border-secondary-darkest xl:divide-y xl:divide-primary-light xl:bg-primary-semilight xl:opacity-100 xl:shadow-md xl:transition-none ltr:rounded-tr-lg rtl:rounded-tl-lg",
        isDropdownOpen
          ? `h-[${sublinks.length * 48}px] opacity-100`
          : "invisible h-0 opacity-0"
      )}
    >
      {sublinks.map((sublink, index) => (
        <SubLink
          key={index}
          sublink={sublink}
          NavLinkProps={NavLinkProps}
          hash={hash}
        />
      ))}
    </div>
  );
};

export default SubLinks;

SubLinks.propTypes = {
  headerIsOnTop: PropTypes.bool,
  isDropdownOpen: PropTypes.bool,
  sublinks: PropTypes.array,
  hash: PropTypes.string,
};
